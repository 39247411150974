import React from 'react';


export function isWithinBounds(radius, currentLocation, geolocation) {

    var d = distance(currentLocation.lat, currentLocation.lng, geolocation.lat, geolocation.lng, 'K');
    // console.log("current location: " + mapCurrentLocation.lat + "  " + mapCurrentLocation.lng);
    // console.log("geoloc : " + geolocation.lat + "  " + geolocation.lng);

    if (d < radius)
        return true;
    else
        return false;
}


export function distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }
    else {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lon1 - lon2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
            dist = 1;
        }
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") { dist = dist * 1.609344 }
        if (unit === "N") { dist = dist * 0.8684 }
        return dist;
    }
}

// Another function for calculating the distance
// function degreesToRadians(degrees) {
//     return degrees * Math.PI / 180;
//   }
  
//   export function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
//     var earthRadiusKm = 6371;
  
//     var dLat = degreesToRadians(lat2-lat1);
//     var dLon = degreesToRadians(lon2-lon1);
  
//     lat1 = degreesToRadians(lat1);
//     lat2 = degreesToRadians(lat2);
  
//     var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
//             Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
//     return earthRadiusKm * c;
//   }

import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Chats from "./Chats";
import axios from "axios";
import { useCookies } from "react-cookie";

let local_data = [];
let chat_user_name_entered = "";

function ChatView() {
//  const url = `http://${process.env.REACT_APP_BE_DB_SERVER_ADDRESS}:${process.env.REACT_APP_BE_DB_SERVER_PORT}/api/chats`;

  // Use when in cloud
 //const url = `${process.env.REACT_APP_BE_DB_SERVER_ADDRESS}:${process.env.REACT_APP_BE_DB_SERVER_PORT}/api/chats`;
const url = `${process.env.REACT_APP_BE_DB_SERVER_ADDRESS}/api/chats`;

  const [chat, setChat] = useState(null);
  const [chats, setChats] = useState(local_data);
  const [refreshData, setRefreshData] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(["BBChatUserName"]);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        if (response.error) {
          console.log("There was an error please refresh or try again later");
        } else {
          // console.log("response.data: " + response.data);
          setChatsList(response.data);
        }
      })
      .catch(() => {
        /*request = { loading: false, data: null, error: true }*/
      });
    // This is just an example for a cleanup function that will be called after every
    // render. the name of the function (in this case cleanup) is arbitrary
    // return function cleanup() {
    //   // console.log("CLEANING UP");
    // }
    // }
  }, [url, refreshData]);

  useEffect(() => {
    // console.log("useEffect3 for adding chat was called");
    if (chat !== null) {
      let new_chat = {
        name: chat.name,
        msg: chat.msg,
      };
      setChat(null);
      axios
        .post(url, new_chat)
        .then((response) => {
          addChatToList(response.data);
        })
        .catch(() => {
          /* request = { loading: false, data: null, error: true }*/
        });
    }
  }, [chat, url]);

  function addChatToList(chat) {
    local_data = [
      ...local_data,
      {
        name: chat.name,
        msg: chat.msg,
        createdAt: chat.createdAt,
      },
    ];

    setChats([...local_data]);
  }

  function addNewChat(name, msg) {
    setChat({
      name: name,
      msg: msg,
    });
  }

  function newChatSubmit() {
    addNewChat(cookies.BBChatUserName, text_entered);
    // refresh();
  }

  function chatUserNameSubmit() {
    if (chat_user_name_entered) {
      setCookie("BBChatUserName", chat_user_name_entered);
    } else {
      alert("Please Type in a name or a nickname");
    }
  }

  function setChatsList(chats_list) {
    let filtered_chats_list = [];
    filtered_chats_list = [...chats_list];
    local_data = [];

    filtered_chats_list.map((chat) => {
      local_data.push({
        name: chat.name,
        msg: chat.msg,
        createdAt: chat.createdAt,
      });
    });

    setChats([...local_data]);
  }

  function refresh() {
    var current_time = new Date();
    setRefreshData(current_time);
  }

  function resetCookie() {
    removeCookie("BBChatUserName");
  }

  //resetCookie();

  let text_entered = "";

  const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);

  function setTimerForRefresh() {
    var myVar = setInterval(myTimer, 600000); // 10,000 is 10 sec
    function myTimer() {
      //console.log("chat refresh timer waking up to do work");
      var d = new Date();
      //console.log(d.toLocaleTimeString());
      refresh();
    }
  }

  // setTimerForRefresh();

  return (
    <Form style={{ color: "blue" }}>
      <Chats chats={chats}> </Chats>

      {!cookies.BBChatUserName && (
        <div>
          <Form.Label style={{ color: "green" }}>
            To start chatting we need you to set a user name.
          </Form.Label>
          <Form.Group controlId="chatUserName">
            <Form.Control
              type="name"
              placeholder="Type in your name or nickname"
              onChange={(e) => {
                chat_user_name_entered = e.target.value;
              }}
            />
          </Form.Group>

          <Form.Group controlId="chatUserNameButtons">
            <Button
              variant="outline-primary"
              type="submit"
              onClick={() => chatUserNameSubmit()}
            >
              Submit
            </Button>
            {"  "}

            <Button variant="outline-primary" href="/">
              Cancel
            </Button>
          </Form.Group>
        </div>
      )}
      {cookies.BBChatUserName && (
        <div>
          <Form.Label style={{ color: "red" }}>What's on your mind?</Form.Label>
          <Form.Group controlId="chatTextarea1">
            {/* <Form.Label>Example textarea</Form.Label> */}
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) => {
                text_entered = e.target.value;
              }}
            />
          </Form.Group>

          <Form.Group controlId="formButtons">
            <Button
              variant="outline-primary"
              type="submit"
              onClick={() => newChatSubmit()}
            >
              Submit
            </Button>
            {"  "}

            <Button variant="outline-primary" href="/">
              Cancel
            </Button>
            {"  "}

            <Button
              variant="outline-primary"
              type="refresh"
              onClick={() => refresh()}
            >
              Refresh
            </Button>
          </Form.Group>
        </div>
      )}
    </Form>
  );
}

export default ChatView;

import React from "react";
import { Card } from "react-bootstrap";

function Chats(props) {
  // we get props (poi data) from the parent component MapView.js and save it in pois.
  const { chats } = props;

  const display_chats = chats.map((the_chat, index) => (
    <Card body key={index}>
      From: {the_chat.name}
      <Card.Text>{the_chat.msg}</Card.Text>
    </Card>
  ));

  return <div>{display_chats}</div>;
}

export default Chats;
